<template>
    <div class="global-content">
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="筛选条件" class="filter">
                <!-- <van-cell title="按设备名称查" :value="filter.dev.value" is-link @click="filter.dev.showPicker = true"/> -->
                <!-- <van-cell title="按告警类型查" :value="filter.way.value" is-link @click="filter.way.show = true"/> -->
                <van-cell title="按告警状态查" :value="filter.status.value" is-link @click="filter.status.show = true"/>
                <van-cell title="选择开始时间" :value="filter.time.startVal" is-link @click="filter.time.startShow = true"/>
                <van-cell title="选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
                <van-row class="text-center">
                    <van-col span="12"><van-button color="#23b593" size="small" @click="resetData">重置</van-button></van-col>
                    <van-col span="12"> <van-button color="#23b593" size="small" @click="filterData">筛选</van-button></van-col>
                </van-row>
            </van-collapse-item>
        </van-collapse>
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" :immediate-check="false">
                    <div v-for="(item,index) in dataList" :key="index"
                        :class="{'block':true,'pass':item.status !== 0}" 
                    >
                        <div class="flex bottom-line">
                            <div class="info-title">
                                <div>{{item.content || '暂无名称'}}</div>
                                <div>{{item.updateTime || '0000-00-00 00:00:00'}}</div>
                            </div>
                        </div>
                        <div class="type">告警级别：<span>{{item.level || '无'}}</span></div>
                        <div class="time">告警状态：<span>{{item.status == 0 ? '未完成' : '已完成'}}</span></div>
                        <div class="time">发布内容：{{item.remarks}}</div>
                        <!-- <div class="time">设备编号：{{item.devCode}}</div> -->
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <!-- 设备编号 -->
        <van-popup v-model="filter.dev.showPicker" round position="bottom">
            <van-picker show-toolbar :columns="filter.dev.columns" @cancel="filter.dev.showPicker = false" @confirm="devConfirm" />
        </van-popup>
        <!-- 告警类型-->
        <!-- <van-action-sheet v-model="filter.way.show" :actions="filter.way.actions" @select="waySelect" /> -->
        <!-- 告警状态-->
        <van-action-sheet v-model="filter.status.show" :actions="filter.status.actions" @select="statusSelect" />
        <!-- 筛选时间段 -->
        <van-popup v-model="filter.time.startShow" round position="bottom">
        <van-datetime-picker
            v-model="filter.time.start"
            type="datetime"
            title="选择开始时间"
            @confirm="confromStartTime"
            @cancel="filter.time.startShow = false"
        />
        </van-popup>
        <van-popup v-model="filter.time.endShow" round position="bottom">
        <van-datetime-picker
            v-model="filter.time.end"
            type="datetime"
            title="选择结束时间"
            @confirm="confromEndTime"
            @cancel="filter.time.endShow = false"
        />
        </van-popup>
        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/rxroad/tabBar.vue'
import moment from "moment";
import { ImagePreview } from 'vant';

export default {
    name:'Warn',
    components: {
        tabBar
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            dataList:[],
            activeName: '',
            filter:{
                dev:{
                    devCode:'',
                    value:'',
                    showPicker:false,
                    columns:[]
                },
                time:{
                    start:new Date(),
                    end:new Date(),
                    startVal:'',
                    endVal:'',
                    startShow:false,
                    endShow:false,
                },
                // way:{
                //     code:'',
                //     value:'',
                //     show:false,
                //     actions:[
                //         {name:'上下线告警',id:0},
                //         {name:'结冰告警',id:1}                    ]
                // },
                status:{
                    code:'',
                    value:'',
                    show:false,
                    actions:[
                        {name:'未完成',id:0},
                        {name:'已完成',id:2},
                    ]
                },
            },
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.getDataList();
        this.getDevInfo();
        setTimeout(()=>{
            this.loading = false;
        },1000)
    },
    methods:{
        // 筛选告警类型
        // waySelect(v){
        //     this.filter.way.show = false;
        //     this.filter.way.value = v.name;
        //     this.filter.way.code = v.id;
        // },
        // 筛选告警状态
        statusSelect(v){
            this.filter.status.show = false;
            this.filter.status.value = v.name;
            this.filter.status.code = v.id;
        },
        // 确认开始时间
        confromStartTime(value){
            this.filter.time.startVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
            this.filter.time.startShow = false;
        },
        // 确认结束时间
        confromEndTime(value){
            this.filter.time.endVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
            this.filter.time.endShow = false;
        },
        getDataList(){
            const data = {
                devCode:this.filter.dev.devCode ? this.filter.dev.devCode : void 0,
                beginTime:this.filter.time.startVal || void 0,
                endTime:this.filter.time.endVal || void 0,
                projectCode:this.projectCode,
                status:this.filter.status.code !== null && this.filter.status.code !== '' ? this.filter.status.code : void 0
            }
            this.$api.RXROAD.alarmInfoPage(this.pageNum,this.pageSize,data).then( d =>{
                if(Array.isArray(d)){
                    this.loading = false;
                    this.dataList = this.dataList.concat(d);
                    this.pageSize > d.length ? this.finished = true : void 0;
                    this.pageNum ++;
                }
            })
        },
        // 获取设备信息
        getDevInfo(){
            // this.$api.JCW.deviceInfoList({
            //     projectCode:this.projectCode
            // }).then( d => {
            //     d.forEach(element => {
            //         element.text = element.devName
            //     });
            //     this.filter.dev.columns = d
            // })
        },
        // 重置
        resetData(){
            this.filter.dev.value = '';
            this.filter.dev.devCode = '';
            this.filter.time.startVal = '';
            this.filter.time.endVal = '';
            this.filter.status.code = '';
            this.filter.status.value='';
            // this.filter.way.code = '';
            // this.filter.way.value='';
        },
        // 筛选
        filterData(){
            this.loading = true;
            this.finished = false;
            this.activeName = '';
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选设备编号确认事件
        devConfirm(v){
            this.filter.dev.showPicker = false;
            this.filter.dev.value = v.text;
            this.filter.dev.devCode = v.devCode;
        },
        // 筛选数据类型确认事件
        dataTypeConfirm(v){
            this.loading = true;
            this.dataType.code = v.val;
            this.dataType.value = v.text;
            this.dataType.showPicker = false;
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选时间确认事件
        timeConfirm(date) {
            const [start, end] = date;
            this.filter.time.show = false;
            this.filter.time.start = moment(start).format("yyyy-MM-DD");
            this.filter.time.end = moment(end).format("yyyy-MM-DD");
            this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
        },
        // 预览图片
        previewImg(url){
            ImagePreview([url]);
        }
    }
}
</script>
<style lang="scss" scoped>
.global-content{
    text-align: left;
    .text-center{
        text-align: center;
        button{
            padding: 0 20px;
            margin-top: 5%;
        }
    }
    .van-cell__value{
        text-align: right;
    }
}
.van-empty{margin: 25% 0;}
.inner{
    text-align: left;
    padding-bottom: 22%;
    .block{
        padding: 4%;
        background-color: #d0f3e1;
        font-size: 0.8rem;
        border: 1px solid #fff;
        margin: 2%;
        border-radius: 10px;
        .bottom-line{padding-bottom: 2%;border-bottom: 1px solid #fff;}
        .top-line{padding-top: 2%;border-top: 1px solid #fff;}
        .flex{
            display: flex;
            margin: 2% 0;
            font-weight: bold;
            .info-title{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
        .time{
            color: #656565;margin-top:2%;
            span{
                font-weight: bolder;
            }
        }
        .type{
            font-weight: bold;
            color: #d4822e;
            margin-top:2%;
        }
        .hint{
            font-size: 0.7rem;
            color: #9e9e9e;
            margin-top: 2%;
        }
    }
    .pass{
        background-color: #e4e4e4;
    }
    .tag{
        height: 100%;
        margin-left: 10px;
    }
}
</style>